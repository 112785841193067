import React from 'react'
import { tabTitle } from '../../utils/generalFuntions'

const HomeTitle = () => {
  tabTitle('Abin T Jose - Full Stack Web Developer Based on Kerala, India')
  return (
    <></>
  )
}

export default HomeTitle