import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./header.css";

const Header = () => {

  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    //when the scroll is higher than 200 viiewport height, add the scroll-header class to a tag with the header tag
    if (this.scrollY >= 80) header.classList.add("scroll-header")
    else header.classList.remove("scroll-header");
  })


  // Toggle Menu
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("home");

  return (
    <header className='header'>
      <nav className='nav container'>
        <Link to="/" onClick={() => setActiveNav('home')} className={activeNav === "home" ? "nav__logo nav__link active-link" : "nav__logo nav__link"}>
          Abin T Jose
        </Link>
        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className='nav__list grid'>
            <li className='nav__item'>
              <Link to="/" onClick={() => setActiveNav('home')} className={activeNav === "home" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-estate nav__icon"></i> Home
              </Link>
            </li>
            <li className='nav__item'>
              <Link to="/about-me" onClick={() => setActiveNav('about-me')} className={activeNav === "about-me" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-user nav__icon"></i> About
              </Link>
            </li>
            {/* <li className='nav__item'>
              <Link to="/skills" onClick={() => setActiveNav('skills')} className={activeNav === "skills" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-file-alt nav__icon"></i> Skills
              </Link>
            </li> */}
            <li className='nav__item'>
              <Link to="/services" onClick={() => setActiveNav('services')} className={activeNav === "services" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-briefcase-alt nav__icon"></i> Services
              </Link>
            </li>
            <li className='nav__item'>
              <Link to="/testimonials" onClick={() => setActiveNav('testimonials')} className={activeNav === "testimonials" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-scenery nav__icon"></i> Testimonials
              </Link>
            </li>
            {/* <li className='nav__item'>
              <Link to="/portfolio" onClick={() => setActiveNav('portfolio')} className={activeNav === "portfolio" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-scenery nav__icon"></i> Portfolio
              </Link>
            </li> */}
            <li className='nav__item'>
              <Link to="/contact-me" onClick={() => setActiveNav('contact')} className={activeNav === "contact" ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-message nav__icon"></i> Contact
              </Link>
            </li>
          </ul>

          <i class="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
        </div>
        <div className='nav__toggle' onClick={() => showMenu(!Toggle)}>
          <i class="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
}

export default Header;