import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Loader.css';

const Loader = () => {
  const textRef = useRef(null);

  useEffect(() => {
    if (!textRef.current) return;

    // Fade in the text
    gsap.fromTo(
      textRef.current,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.2,
        ease: 'power2.out',
        onComplete: () => {
          setTimeout(() => {
            if (textRef.current) {
              textRef.current.parentElement.classList.add('hidden'); // Add class to hide
            }
          }, 100);
        }
      }
    );
  }, []);

  return (
    <div className="loader">
      <div className="loader-text" ref={textRef}>
        {'abintjose.com'.split('').map((letter, index) => (
          <span key={index} className="letter">
            {letter}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Loader;
