import React from 'react'
import "./testimonials.css";
import { Data } from './Data';
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/swiper.min.css";
// import 'swiper/modules/pagination/pagination.min.css'

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import required modules
import { Pagination } from "swiper";
import { tabTitle } from '../../utils/generalFuntions';

const Testimonials = () => {
  tabTitle('Testimonials - Abin T Jose - Full Stack Web Developer Based on Kerala, India');
  return (
    <section className="testimonial container section" id="testimonial">
      <h2 className="section__title">What my clients say</h2>
      <span className="section__subtitle">Testimonials</span>

      <Swiper className="testimonial__container mySwiper"
        // slidesPerView={1}
        loop={true}
        grabCursor={true}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          570: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 48,
          },
          // 1024: {
          //   slidesPerView: 5,
          //   spaceBetween: 50,
          // },
        }}
        modules={[Pagination]}
      >
        {Data.map(({ id, image, title, description }) => {
          return (
            <SwiperSlide className="testimonial__card" key={id}>
              <img src={image} alt={title} className="testimonial__img" />
              <h3 className="testimonial__name">{title}</h3>
              <p className="testimonial__description">{description}</p>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section >
  )
}

export default Testimonials