import React, { useState } from 'react';
import "./services.css";
import { tabTitle } from '../../utils/generalFuntions';

const Services = () => {
  const [toggleState, setToggleState] = useState(0);
  const [showAllServices, setShowAllServices] = useState(false);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  // Define the services data from the JSON object
  const servicesData = [
    {
      "name": "Web Development",
      "description": "Building complete web applications from scratch using a combination of front-end and back-end technologies."
    },
    {
      "name": "Front-End Development",
      "description": "Creating interactive and visually appealing user interfaces using HTML, CSS, and JavaScript frameworks."
    },
    {
      "name": "Back-End Development",
      "description": "Developing server-side logic, handling database operations, and building APIs using server-side languages like Node.js, Python, Ruby, or PHP."
    },
    {
      "name": "Database Management",
      "description": "Designing and implementing efficient database schemas, optimizing queries, and ensuring data integrity."
    },
    {
      "name": "API Development",
      "description": "Creating robust and scalable APIs (Application Programming Interfaces) for seamless integration with other applications or services."
    },
    {
      "name": "Website Maintenance and Updates",
      "description": "Providing ongoing support and updates to ensure the smooth functioning and security of websites or web applications."
    },
    {
      "name": "E-commerce Development",
      "description": "Building online stores and implementing secure payment gateways for businesses to sell products or services online."
    },
    {
      "name": "Mobile Application Development",
      "description": "Developing cross-platform or native mobile applications using frameworks like React Native or Flutter."
    },
    {
      "name": "CMS Development",
      "description": "Building custom CMS solutions or working with popular CMS platforms like WordPress, Drupal, or Joomla."
    },
    {
      "name": "Deployment and Hosting",
      "description": "Setting up and configuring servers, deploying web applications to cloud platforms, and managing hosting environments."
    },
    {
      "name": "Performance Optimization",
      "description": "Analyzing and optimizing web application performance, including code efficiency, database queries, and page load times."
    },
    {
      "name": "Testing and Quality Assurance",
      "description": "Implementing testing strategies, writing unit tests, and conducting quality assurance to ensure bug-free and reliable software."
    },
    {
      "name": "Code Reviews and Refactoring",
      "description": "Reviewing existing codebases, identifying areas for improvement, and refactoring code for better maintainability and scalability."
    },
    {
      "name": "Consulting and Technical Guidance",
      "description": "Providing expert advice and guidance on technology choices, architecture, and best practices for web development projects."
    }
    // Add more services here if needed
  ];

  // Determine the number of services to display initially
  const numServicesToShow = showAllServices ? servicesData.length : 5;
  const displayedServices = servicesData.slice(0, numServicesToShow);

  const remainingServices = servicesData.slice(numServicesToShow);
  tabTitle('Services Abin T Jose - Full Stack Web Developer Based on Kerala, India');
  return (
    <section className="services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">What I offer</span>
      <div className="services__container container grid">
        {displayedServices.map((service, index) => (
          <div className="services__content" key={index}>
            <div>
              <i className="uil uil-web-grid services__icon"></i>
              <h3 className="services__title">{service.name}</h3>
            </div>
            <span className="services__button" onClick={() => toggleTab(index + 1)}>View more
              <i className="uil uil-arrow-right services__button-icon"></i>
            </span>

            <div className={toggleState === index + 1 ? "services__modal active-modal" : "services__modal"}>
              <div className="services__modal-content">
                <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>
                <h3 className="services__modal-title">{service.name}</h3>
                <p className="services__modal-description">{service.description}</p>
              </div>
            </div>
          </div>
        ))}

        {!showAllServices && remainingServices.length > 0 && (
          <div className="services__content">
            <div>
              <i className="uil uil-arrow services__icon"></i>
              <h3 className="services__title">View More</h3>
            </div>
            <span className="services__button" onClick={() => setShowAllServices(true)}>View More
              <i className="uil uil-arrow-right services__button-icon"></i>
            </span>
          </div>
        )}

        {showAllServices && remainingServices.map((service, index) => (
          <div className="services__content" key={index + numServicesToShow}>
            <div>
              <i className="uil uil-web-grid services__icon"></i>
              <h3 className="services__title">{service.name}</h3>
            </div>
            <span className="services__button" onClick={() => toggleTab(index + numServicesToShow + 1)}>View more
              <i className="uil uil-arrow-right services__button-icon"></i>
            </span>

            <div className={toggleState === index + numServicesToShow + 1 ? "services__modal active-modal" : "services__modal"}>
              <div className="services__modal-content">
                <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>
                <h3 className="services__modal-title">{service.name}</h3>
                <p className="services__modal-description">{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;