// import Image1 from "../../assets/testimonial1.png";
// import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/vipin-murali.jpg";
import Image4 from "../../assets/sajitha-nair.jpg";
import Image5 from "../../assets/k-p-g-nair.jpg";
import Chani from "../../assets/Chani.jpg";
import Aravind_Venugopal from "../../assets/Aravind Venugopal.jpg";
import Ashley_Warren from "../../assets/Ashley Warren.jpg";
import Jeremy_Picker from "../../assets/Jeremy Picker.jpg";
import Astha from "../../assets/Astha.jpg";
import Advocitude from "../../assets/Advocitude.jpg";
import Kelsey_Jones from "../../assets/Kelsey Jones.jpg";


export const Data = [
  {
    id: 10,
    image: Kelsey_Jones,
    title: "Kelsey Jones",
    description: "Abin provided exceptional service and support throughout the project. He was attentive to my requirements and delivered a solution that met all my expectations. I'm grateful for his expertise and dedication.",
  },
  {
    id: 5,
    image: Image5,
    title: "KPG Nair",
    description: "I had a great experience working with Abin. He demonstrated deep knowledge in his field and provided innovative solutions. I appreciate his dedication and commitment to delivering a top-notch product for KSMS.",
  },
  {
    id: 1,
    image: Chani,
    title: "Chani",
    description: "I'm extremely satisfied with the work done by Abin. He demonstrated excellent technical skills and attention to detail throughout the project. The final result exceeded my expectations.",
  },
  {
    id: 2,
    image: Aravind_Venugopal,
    title: "Aravind Venugopal",
    description: "Working with Abin was a great experience. He provided timely updates, listened to my requirements, and delivered a high-quality product. I would highly recommend his services.",
  },
  {
    id: 3,
    image: Image3,
    title: "Vipin Murali",
    description: "I'm very impressed with the expertise and professionalism of Abin. He tackled complex challenges with ease and delivered a flawless solution. I look forward to working with him again.",
  },
  {
    id: 4,
    image: Image4,
    title: "Sajitha Nair",
    description: "Abin exceeded my expectations with his exceptional work. He was responsive, collaborative, and delivered the project on time. I'm highly satisfied with the results.",
  },

  {
    id: 6,
    image: Ashley_Warren,
    title: "Ashley Warren",
    description: "He went above and beyond to ensure the success of the project. His attention to detail, proactive communication, and technical expertise were exceptional. I highly recommend his services.",
  },
  {
    id: 7,
    image: Jeremy_Picker,
    title: "Jeremy Picker",
    description: "I'm extremely pleased with the level of professionalism and quality of work provided by Abin. He understood my requirements and delivered a solution that exceeded my expectations. I would definitely work with him again.",
  },
  {
    id: 8,
    image: Astha,
    title: "Astha",
    description: "Working with Abin was a breeze. He demonstrated strong problem-solving skills and attention to detail. The project was completed on time and within budget. I'm highly satisfied with his services.",
  },
  {
    id: 9,
    image: Advocitude,
    title: "Advocitude",
    description: "I'm impressed with the professionalism and expertise of Abin. He was responsive to my needs, communicated effectively, and delivered a high-quality product. I highly recommend his services.",
  }
];

//export default Data;