import React from 'react';

const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend Developer</h3>
      <div className="skills__box">

        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Programming languages</h3>
              <span className='skills__level'>Python and Javascript</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Web frameworks</h3>
              <span className='skills__level'> Django (Python), Express.js (Node.js)</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Relational databases</h3>
              <span className='skills__level'>MySQL, PostgreSQL</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">NoSQL databases</h3>
              <span className='skills__level'>MongoDB, Cassandra</span>
            </div>
          </div>

        </div>
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">API development</h3>
              <span className='skills__level'>RESTful APIs</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Caching mechanisms</h3>
              <span className='skills__level'>Redis, Memcached</span>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Authentication authorization</h3>
              <span className='skills__level'>OAuth, JWT</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Testing and debugging</h3>
              <span className='skills__level'>identify and fix issues </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Backend;