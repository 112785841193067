import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./footer.css";

const Footer = () => {
  const [activeNav, setActiveNav] = useState("home");
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">abintjose.com</h1>
        <ul className="footer__list">
          <li>
            <Link to="/" onClick={() => setActiveNav('home')} className={activeNav === "home" ? "footer__link active-link" : "footer__link"}>Home </Link>
          </li>
          <li>
            <Link to="/about-me" onClick={() => setActiveNav('about-me')} className={activeNav === "about-me" ? "footer__link active-link" : "footer__link"}>About </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => setActiveNav('services')} className={activeNav === "services" ? "footer__link active-link" : "footer__link"}>Services</Link>
          </li>
          <li>
            <Link to="/testimonials" onClick={() => setActiveNav('testimonial')} className={activeNav === "testimonial" ? "footer__link active-link" : "footer__link"}>Testimonials</Link>
          </li>
          <li className='nav__item'>
            <Link to="/contact-me" onClick={() => setActiveNav('contact')} className={activeNav === "contact" ? "footer__link active-link" : "footer__link"}>Contact</Link>
          </li>
        </ul>
        <div className="footer__social">
          <a href="https://facebook.com/abintjose" className="footer__social-link" target="_blank">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="https://twitter/abintjose" className="footer__social-link" target="_blank">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="https://linkedin.com/in/abintjose" className="footer__social-link" target="_blank">
            <i className="bx bxl-linkedin"></i>
          </a>
          <a href="https://github.com/abin-xlri" className="footer__social-link" target="_blank">
            <i className="bx bxl-github"></i>
          </a>
        </div>
        <span className="footer__copyright">&#169; abintjose.com All rights reserved.</span>
      </div>
    </footer>
  )
}

export default Footer